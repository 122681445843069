<template>
    <div class="trending">
        <div class="trendingMain">
            <!-- <div class="trending-title">
                <span>Home /</span>
                <span>Trending</span>
            </div> -->
            <div class="trending-banner">
                <h5>TRENDING</h5>
            </div>
            <div class="trending-main">
                <div class="main-nav">
                    <span :class="slectedId == '' ? 'ative' : ''" @click="slectedId = '';categoryClick(slectedId)">ALL</span>
                    <span v-for="(item, index) in classifyList" :key="index" :class="item.category_id == slectedId ? 'ative' : ''" @click="slectedId = item.category_id;categoryClick(item.category_id)">{{ item.category_name }}</span>
                </div>
                <div class="main-list">
                    <!-- :width="itemWidth" :gutterWidth="gutterWidth" -->
                    <waterfall :col="col" :data="trendingList" @loadmore="loadmore" @scroll="scroll" :isTransition="true">
                        <template>
                            <div class="cell-item item" v-for="(item, index) in trendingList" :key="index">
                                <div class="item-img" @click="toDetailClick(item.sku_id)">
                                    <div class="img">
                                        <el-image :lazy-src="item.goods_image[0]" :src="item.goods_image[0]" fit="cover" style="cursor: pointer;height: 100%;"></el-image>
                                    </div>
                                    <div class="text">
                                        <p>TOP:<span>{{index + 1}}</span></p>
                                    </div>
                                    <button>ADD TO BAG</button>
                                </div>
                                <div class="item-text">
                                    <span>{{item.goods_name}}</span>
                                    <img src="@/assets/images/index/tding/xing.png" />
                                </div>
                                <div class="item-price">
                                    <span>US{{strCurXSoyego}}{{item.discount_price}}</span>
                                </div>
                                <div class="item-color">
                                    <div v-if="item.goods_spec_format != null && item.goods_spec_format[0].value.length > 0">
                                        <div @click="item.goods_spec_format[0].colorSoyego = item1.sku_id" v-for="(item1, index1) in item.goods_spec_format[0].value" :key="index1" :class="item.goods_spec_format[0].colorSoyego == item1.sku_id ? 'outer-round round-ative' : 'outer-round'">
                                            <div :class="'round round-' + item1.spec_value_name"></div>
                                        </div>
                                    </div>
                                    <div v-else></div>
                                </div>
                            </div>
                        </template>
                    </waterfall>
                </div>
            </div>
        </div>
        <div class="end">
            <span>End</span>
        </div>
    </div>
</template>

<style>
.trending .vue-waterfall .vue-waterfall-column{
    width: calc((100% - 100px) / 5) !important;
    padding-right: 25px;
}
.trending .vue-waterfall .vue-waterfall-column:last-child{
    padding-right: 0;
}
.about-content{
    background: #F7F9FA;
}
</style>
<style lang="scss" scoped>
@import "./css/trending.scss";
</style>

<script>
import trending from "./js/trending.js"

export default {
    name: "trending",
    components: {},
    mixins: [trending]
}
</script>