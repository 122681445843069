import { tree } from "@/api/goods/goodscategory"

export default {
    name: "trending",
    components: {

    },
    data: () => {
        return {
            trendingList: [],
            col: 5,
            strCurXSoyego: localStorage.getItem("strCurXSoyego"),
            classifyList: [],
            slectedId: '',
            categoryId: 0,
        }
    },
    watch: {

    },
    created() {
        
    },
    mounted() {
        this.getTree();
        this.getTdingList();
    },
    computed: {
        // itemWidth() {
        //     return ((document.documentElement.clientWidth - 112 - 100) / 5)  //计算宽度
        // },
        // gutterWidth() {
        //     return (100 / 5)	//计算x轴方向margin(y轴方向的margin自定义在css中即可)
        // }
    },
    methods: {
        getTree() {
            tree({
                level: 1
            }).then((res) => {
                if (res.code == 0 && res.data) {
                    this.classifyList = res.data;
                    // console.log(this.classifyList)
                }
            }).catch((err) => {
                this.$message.error(err.message)
            })
        },
        getTdingList() {
            let data = JSON.parse(localStorage.getItem("TdingListSoyego"));
            data.forEach(item => {
                item.category_id = item.category_id.split(",");
                if(this.categoryId == 0){
                    item.goods_image = item.goods_image.split(',');
                    item.goods_spec_format = JSON.parse(item.goods_spec_format);
                    this.$set(item.goods_spec_format[0], 'colorSoyego', item.goods_spec_format[0].value[0].sku_id);
                }else if(this.categoryId == item.category_id[1]){
                    item.goods_image = item.goods_image.split(',');
                    item.goods_spec_format = JSON.parse(item.goods_spec_format);
                    this.$set(item.goods_spec_format[0], 'colorSoyego', item.goods_spec_format[0].value[0].sku_id);
                }
            });
            this.trendingList = data;
            // console.log(this.trendingList)
        },
        scroll(scrollData) {
            // console.log(scrollData)
            // if(scrollData.diff <= 500){
            //     if(this.dropsList.length > 0){
            //         this.loadmore();
            //     }
            // }
        },
        toDetailClick(id) {
			localStorage.setItem('productDetailsDropSoyego', false);
            const blank = this.$router.resolve({path: '/productDetails', query: {sku_id: id}})
            window.open(blank.href,'_blank')
		},
        switchCol(col) {
            // this.col = col
            // console.log(this.col)
        },
        // 触底事件
        loadmore(index) {
            // this.currentPage = this.currentPage + 1;
            // if(this.dropsList.length > 0){
            //     this.getTimeList();
            // }
            // this.trendingList = this.trendingList.concat(this.trendingList);
        },
        categoryClick(id) {
            this.categoryId = id;
            this.currentPage = 1;
            this.getTdingList();
        }
    }
}
